import videowall from '../../../assets/images/products-videowall.jpg';
import polycamera from '../../../assets/images/products-polycamera.png';
import rss10000 from '../../../assets/images/products-rss-10000.png';
import ARM from '../../../assets/images/products-ARM.png';
import viewStation from '../../../assets/images/products-viewStation.png';
import './Products.scss';
import { Link } from 'react-router-dom';

const Products: React.FC = () => {
    return (
        <div className="container">
            <section className="products">
                <div className="product">
                    <div className="img">
                        <img src={ARM} alt="ARM" className="product-img" />
                    </div>
                    <h2 className="product-header">АРМ WS OMMATID </h2>
                    <p className="product-text">Автоматизированное рабочее место </p>
                    <Link to="/solutions" className="product-link">ПОДРОБНЕЕ</Link>
                </div>
                <div className="product product-polycamera">
                    <div className="img">
                        <img src={polycamera} alt="polycamera" className="product-img " id='polycamera' />

                    </div>
                    <h2 className="product-header">Polycamera</h2>
                    <p className="product-text">Мультифокальная панорамная камера для наблюдению за большими площадями, в высоком разрешении. </p>
                    <Link to="/solutions" className="product-link">ПОДРОБНЕЕ</Link>
                </div>
                <div className="product">
                    <div className="img">
                        <img src={rss10000} alt="" className="product-img" />
                    </div>
                    <h2 className="product-header">RSS 10 000</h2>
                    <p className="product-text">Высокопроизводительный сервер с многоядерным процессором</p>
                    <Link to="/solutions" className="product-link">ПОДРОБНЕЕ</Link>
                </div>
            </section>
        </div>
    )
}

export default Products;